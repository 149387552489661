<template>
  <div class="ad-submit-confirmation">
    <div class="container h-100 pt-5">
      <div class="row h-100 align-items-center">
        <div class="col-12 col-md-6 offset-md-3">
          <div class="card shadow sm">
            <div class="card-body">
              <h1 class="text-center">Thank you!</h1>
              <hr/>
              <p>Your advertise was added successfully.</p>
              <p>Payment ID: {{(paymentId && paymentId != 'undefined') ? paymentId : ''}}</p>
              <p>You can view it in your Dashboard or by following below url</p>
              <p>
                <router-link v-if="type === 'property'" :to="'/ad-details/' + id" class="me-auto text-decoration-none">
                  Show my premises advertise
                </router-link>
                <router-link v-if="type === 'service'" :to="'/ad-service/' + id" class="me-auto text-decoration-none">
                  Show my service advertise
                </router-link>
              </p>
  <!--            <p>{{ id }}</p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name:"AdSubmitConfirmation",
  props: ['id', 'type', 'paymentId'],
  data(){
    return {
      processing:false
    }
  }
}
</script>

<style lang="scss">

.ad-submit-confirmation {
  min-height: 500px;
}

</style>